import React, { Component } from "react";
import CompLogin from '../components/CompLogin'

export class Login extends Component {

    render() {
        return (
            <CompLogin/>
        )
    }
}