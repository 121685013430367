import React from 'react'
import Navbar from './components/Navbar';
import Topbar from './components/Topbar';

const Layout = (props) => {
    return (<>
        <Topbar />
        {props.children}
        <Navbar />
    </>)
}

export default Layout;