import React, { useContext } from 'react'
import Logo from '../img/Logo.png'
import '../css/Topbar.css'

import AuthContext from '../contexts/AuthContext'

const Topbar = () => {
    const [user, serUser] = useContext(AuthContext)

    return (<>
        <div className='Topbar'>
            <img src={Logo } className="logoTopbar"/>
            <span className='Ola'>Olá {user.name}</span>
        </div>
    </>)
}

export default Topbar;