import React, { Component, useContext } from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as yup from 'yup'
import axios from "axios"
import '../css/CompLogin.css'
import Logo from '../img/Logo.png'
import * as auth from '../services/auth'
// import config from '../config.json'
import {useNavigate} from 'react-router-dom';

import AuthContext from '../contexts/AuthContext'

const validationLogin = yup.object().shape({
    email: yup
        .string()
        .email("Não é um e-mail válido.")
        .required("Este campo é obrigatório!"),
    password: yup
        .string()
        .min(8, "A senha deve ter pelo menos 8 caracteres.")
        .required("Este campo é obrigatório!"),
});

// const handleClickRegister = (values) => {
//     console.log(values);
// }

// const validationRegister = yup.object().shape({
//     email: yup
//         .string()
//         .email("Não é um e-mail válido.")
//         .required("Este campo é obrigatório!"),
//     password: yup
//         .string()
//         .min(8, "A senha deve ter pelo menos 8 caracteres.")
//         .required("Este campo é obrigatório!"),
//     confirmPassword: yup
//         .string()
//         .oneOf([yup.ref("password"), null], "As senhas não são iguais.")
//         .min(8, "A senha deve ter pelo menos 8 caracteres.")
// });

const CompLogin = () => {
    const [user, setUser] = useContext(AuthContext);
    const navigate = useNavigate();

    const handleClickLogin = async (values) => {
        let email = values.email;
        let password = values.password;
    
        let reqBody = {
            email: email,
            password: password
        };
    
        const response = await axios.post("http://localhost:3001/login", reqBody)

        auth.login(response.data.token)

        setUser(response.data)

        navigate('/dashboard');
    };

    return (
        <div className="container">
        <img src={Logo } className="logo"/>
        {/*<h1>Login</h1>*/}
        <Formik 
            initialValues={{}}
            onSubmit={handleClickLogin}
            validationSchema={validationLogin}>
            <Form className="login-form">
            <div className="login-form-group">
                <Field name="email" className="form-field" placeholder="Email"/>
                <ErrorMessage 
                component="span" 
                name="email" 
                className="form-error"
                />
            </div>
            <div className="login-form-group">
                <Field name="password" className="form-field" placeholder="Senha" type="password"/>
                <ErrorMessage 
                component="span" 
                name="password" 
                className="form-error"
                />
            </div>
            <button className="button" type="submit">
                Entrar
            </button>
            </Form>
        </Formik>
        {/*
        <h1>Cadastro</h1>
        <Formik 
            initialValues={{}}
            onSubmit={handleClickRegister}
            validationSchema={validationRegister}>
            <Form className="login-form">
            <div className="login-form-group">
                <Field name="email" className="form-field" placeholder="Email"/>
                <ErrorMessage 
                component="span" 
                name="email" 
                className="form-error"
                />
            </div>
            <div className="login-form-group">
                <Field name="password" className="form-field" placeholder="Senha"/>
                <ErrorMessage 
                component="span" 
                name="password" 
                className="form-error"
                />
            </div>
            <div className="login-form-group">
                <Field name="confirmPassword" className="form-field" placeholder="Confirme sua senha"/>
                <ErrorMessage 
                component="span" 
                name="confirmPassword" 
                className="form-error"
                />
            </div>
            <button className="button" type="submit">
                Login
            </button>
            </Form>
        </Formik>
        */}
        </div>
    );
}

export default CompLogin;