import React, { useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import {Home, Login, Dashboard, OS} from './pages'
import {ROUTES} from './constants'
import { isAuthenticated } from './services/auth'
import AuthContext from './contexts/AuthContext'

const Routers = () => {
    const [user, setUser] = useState({});

    const isAuth = () => {
        return !!user
    }

    return (
        <BrowserRouter>
            <AuthContext.Provider value={[user, setUser]}>
                <Routes>
                    {/* Rotas públicas */}
                    <Route path={ROUTES.RAIZ} element={<Login/>} />
                    <Route path={ROUTES.LOGIN} element={<Login/>} />
                    <Route path={ROUTES.HOME} element={<Home/>} />

                    {/* Rotas privadas */}
                    <Route path={ROUTES.DASHBOARD} element={isAuthenticated() ? <Dashboard/> : <Navigate to={ROUTES.LOGIN} /> } />
                    <Route path={ROUTES.OS} element={isAuthenticated() ? <OS/> : <Navigate to={ROUTES.LOGIN} /> } />
                    
                    <Route path={ROUTES.NOT_FOUND} element={<h1>404 - Page not found.</h1>} />
                </Routes>
            </AuthContext.Provider>
        </BrowserRouter>
    );
};

export default Routers;