import { React, Component } from 'react'
import CompDashboard from '../components/CompDashboard'

import Layout from '../layout'

export class Dashboard extends Component {
    render() {
        return (
            <Layout>
                <CompDashboard/>
            </Layout>
        )
    }
}