import React from 'react'

import '../css/CompDashboard.css'

const CompDashboard = () => {
    return(
        <div className='CompDashboard'>
            <h1>Dashboard</h1>
        </div>
    )
}

export default CompDashboard