import React from 'react'

import '../css/CompOS.css'

const CompOS = () => {
    return(
        <div className='CompOS'>
            <h1>Ordens de serviço</h1>
        </div>
    )
}

export default CompOS