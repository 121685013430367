import { React, Component, useContext } from 'react'
import CompOS from '../components/CompOS'
import { Table, Paper, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import Layout from '../layout'

import AuthContext from '../contexts/AuthContext'

import axios from "axios"

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

export const OS = () => {
    const [user, serUser] = useContext(AuthContext)

    // (async () => {
    //     const oss = await axios.get('http://localhost:3001/getAll/os/' + user.idusers)

    //     console.log(oss)
    // })();

    const oss = axios.get('http://localhost:3001/getAll/os/' + user.idusers)

    console.log(oss)

    const rows = [
        {
            description: "OS 2012",
            status: "completed"
        },
        {
            description: "OS 2013",
            status: "pending"
        }
    ];

    return (
        <Layout>
            <CompOS/>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow
                        key={row.description}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row.description}
                        </TableCell>
                        <TableCell>{row.status}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Layout>
    );
};

// export class OS extends Component {
//     render() {
//         const [user, serUser] = useContext(AuthContext)

//         return (
//             <Layout>
//                 <CompOS/>
//                 <TableContainer component={Paper}>
//                     <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
//                         <TableHead>
//                         <TableRow>
//                             <TableCell>Dessert (100g serving)</TableCell>
//                             <TableCell align="right">Calories</TableCell>
//                             <TableCell align="right">Fat&nbsp;(g)</TableCell>
//                             <TableCell align="right">Carbs&nbsp;(g)</TableCell>
//                             <TableCell align="right">Protein&nbsp;(g)</TableCell>
//                         </TableRow>
//                         </TableHead>
//                         <TableBody>
//                         {/* {rows.map((row) => ( */}
//                             <TableRow
//                             key={user.name}
//                             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//                             >
//                             <TableCell component="th" scope="row">
//                                 {user.name}
//                             </TableCell>
//                             <TableCell align="right">{"TESTE 2"}</TableCell>
//                             <TableCell align="right">{"TESTE 3"}</TableCell>
//                             <TableCell align="right">{"TESTE 4"}</TableCell>
//                             <TableCell align="right">{"TESTE 5"}</TableCell>
//                             </TableRow>
//                         {/* ))} */}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//             </Layout>
//         )
//     }
// }