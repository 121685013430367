import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../constants'
import '../css/Navbar.css'
import * as auth from '../services/auth'

const Navbar = () => {
    return (<>
    <div className='Navbar'>
        <ul>
            <li><Link to={ROUTES.DASHBOARD}>Dashboard</Link></li>
            <li><Link to={ROUTES.OS}>Ordens de serviço</Link></li>
            <li><Link to={ROUTES.LOGIN}>Sair</Link></li>
        </ul>
    </div>
    </>)
}

export default Navbar;